/**
 *   Bootstrap 4 theme for Select2.
 *   https://github.com/nitishrajuprety/bootstrap4-select2-theme
 *
 *   Author: Nitish Raj Uprety <uprety.nitish@gmail.com>
 *   Version: 1.0.0
 */
 
.select2-dropdown {
  border: 1px solid #ced4da !important;
  border-radius: 0 !important;
  color: #495057 !important;
}

.select2-results__option[aria-selected=true] {
  background-color: #b3d7ff !important;
}

.select2-results__option--highlighted[aria-selected] {
  background-color: #007bff !important;
}

.select2-selection--single {
  border-radius: 0 !important;
  border: 1px solid #ced4da !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  height: calc(2.25rem + 2px) !important;
  padding: 0.375rem 1.5rem 0.375rem 0.75rem !important;
}

.select2-selection--single .select2-selection__rendered {
  color: #495057 !important;
  padding: 0 0 0 0 !important;
}

.select2-selection--single .select2-selection__placeholder {
  color: #6c757d !important;
}

.select2-selection--single .select2-selection__arrow {
  height: 100% !important;
}

.select2-selection--single .select2-selection__arrow b {
  border-color: #ced4da transparent transparent transparent !important;
}

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #ced4da transparent !important;
}

.select2-search--dropdown .select2-search__field {
  border: 1px solid #ced4da !important;
  color: #495057 !important;
}

.select2-selection--multiple {
  border-radius: 0 !important;
  border: 1px solid #ced4da !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  min-height: calc(2.25rem + 2px) !important;
}

.select2-selection--multiple .select2-selection__choice {
  background-color: #007bff !important;
  border: 1px solid #0062cc !important;
  border-radius: 0 !important;
  color: #fff !important;
  margin-right: 0.375rem !important;
  margin-top: 0.375rem !important;
  padding: 0 0.375rem !important;
}

.select2-selection--multiple .select2-selection__choice__remove {
  color: #0056b3 !important;
  margin-right: 0.375rem !important;
  font-size: 1.5rem;
  line-height: 0.75;
}

.select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #66b0ff !important;
}